@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';
@import '../../../../bloko/common/styles/colors';
@import '../../../../bloko/common/styles/text-base';

@counter-magritte: 64px;
@counter-magritte-small: 56px;

.counter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.counter_vacancy-body {
    align-items: end;
    justify-content: start;
    position: relative;
    flex-shrink: 0;
    margin-left: 6px;
    height: 70px;
    width: 70px;
}

.counter__circle {
    position: relative;
    width: 60px;
    height: 60px;
}

.counter__circle_success {
    .bloko-text-large();

    background: @color-green-60;
    text-align: center;
    line-height: 60px;
    border-radius: 100%;
}

.counter__circle::after {
    .bloko-text-large();

    content: attr(data-progress);
    display: inline-block;
    position: absolute;
    line-height: 61px;
    text-align: center;
    width: 100%;
    left: 0;
    top: 0;
}

.counter__circle-primary {
    stroke: @color-gray-40;
}

.counter__circle-primary_magritte {
    stroke: @magritte-color-icon-contrast;
}

.counter__circle-secondary {
    stroke: @color-blue-60;
    transition: stroke-dashoffset 0.4s ease-in-out;
}

.counter__circle-secondary_magritte {
    stroke: @magritte-color-icon-accent;
}

.counter__round {
    .bloko-text-large();

    margin-left: 6px;
    line-height: 1;
    color: @color-gray-80;
    text-align: right;
    display: flex;
    position: relative;
    top: -9px;
    will-change: transform;
}

.counter__round_vacancy-body {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    height: 28px;
    width: 43px;
    background: no-repeat center url('images/vacancy-body-round.svg');
}

.counter__delimiter {
    align-self: end;
    padding: 0 1px;
}

.counter__delimiter_vacancy-body {
    align-self: center;
}

.counter__round_digit {
    text-align: center;
}

.counter__badge {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
}

.counter__badge_1 {
    background-image: url('images/badge-1.svg');
}
.counter__badge_2 {
    background-image: url('images/badge-2.svg');
}
.counter__badge_3 {
    background-image: url('images/badge-3.svg');
}
.counter__badge_4 {
    background-image: url('images/badge-4.svg');
}
.counter__badge_5 {
    background-image: url('images/badge-5.svg');
}
.counter__badge_6 {
    background-image: url('images/badge-6.svg');
}
.counter__badge_7 {
    background-image: url('images/badge-7.svg');
}

.counter-circle-magritte {
    min-width: @counter-magritte;
    height: @counter-magritte;
}

.counter-circle-magritte::after {
    .magritte-typography-subtitle-2-semibold();

    color: @magritte-color-text-accent;
    height: @counter-magritte;
    display: flex;
    justify-content: center;
    align-items: center;
}

.counter-circle-magritte_small {
    min-width: @counter-magritte-small;
    width: @counter-magritte-small;
    height: @counter-magritte-small;
}

.counter-circle-magritte_small::after {
    height: @counter-magritte-small;
}

.counter-success-magritte {
    min-width: @counter-magritte;
    height: @counter-magritte;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: @magritte-color-component-card-background-extra-1;
    border: 2px solid @magritte-color-stroke-accent;
    box-sizing: border-box;
}

.counter-success-magritte_small {
    min-width: @counter-magritte-small;
    width: @counter-magritte-small;
    height: @counter-magritte-small;
}
